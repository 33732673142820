.container,
.container-sm,
.container-xs {
	width: 100%;
	margin: 0 auto;
	padding-left: $container--padding__mobile;
	padding-right: $container--padding__mobile;

	@include media( '>small' ) {
		padding-left: $container--padding__desktop;
		padding-right: $container--padding__desktop;
	}
}

.videoContainer .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    background: black;
    opacity: 0.5;
}

#myVideo {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;

}

.container-truck {
    background-image: url('../../../images/main_bg_truck.jpg');
    background-size: cover;
    background-position: right;
    /*position: fixed;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    padding: 20px;*/
}
.container {
	max-width: $container--width + ( $container--padding__desktop * 2 );
}

.container-sm {
	max-width: $container--width-sm + ( $container--padding__desktop * 2 );
}

.container-xs {
	max-width: $container--width-xs + ( $container--padding__desktop * 2 );
}

[class*=container] {

	[class*=container] {
		padding-left: 0;
		padding-right: 0;
	}

    .container-sm {
		max-width: $container--width-sm;
    }

	.container-xs {
		max-width: $container--width-xs;
	}
}

.loader,
.loader:before,
.loader:after {
    background: #ffffff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}

.loader {
    color: #ffffff;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}

.loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 1.5em;
}

@-webkit-keyframes load1 {
    0%, 80%, 100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes load1 {
    0%, 80%, 100% {
        box-shadow: 0 0;
        height: 4em;
    }

    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
